import React, { useState } from 'react';

import ApprovalIcon from '@mui/icons-material/Approval';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import SoftBox from 'components/SoftBox';
import TabPanel, { a11yProps } from 'components/TabPanel';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import MainLayout from 'rootComponents/LayoutContainers/MainLayout';
import MainNavbar from 'rootComponents/Navbars/MainNavbar';
import { useVersionStore } from 'stores/version';

import ApprovalTab from './components/ApprovalTab';
import FeesTab from './components/FeesTab';
import PaymentMethodTab from './components/PaymentMethodTab';
import ReferralTab from './components/ReferralTab';
import VersionTab from './components/VersionTab';

function VersionsDetails() {
  const { id } = useParams();
  const {
    currentVersionState: { name: versionName },
  } = useVersionStore();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <MainLayout>
      <MainNavbar
        customRoutes={[
          { label: 'Settings', route: 'settings/versions' },
          { label: 'Versions', route: `settings/versions` },
          {
            label: versionName || id,
            route: `settings/versions/${id}`,
          },
        ]}
      />

      <SoftBox sx={{ maxWidth: 800 }}>
        <AppBar position='static'>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='version settings tabs'
            sx={{ backgroundColor: 'transparent' }}
            variant='standard'
          >
            <Tab
              icon={<ContactPageIcon />}
              label='Version Settings'
              {...a11yProps(0)}
            />
            <Tab
              icon={<ListAltIcon />}
              label='Fee Settings'
              {...a11yProps(1)}
            />
            <Tab
              icon={<ApprovalIcon />}
              label='Approval Settings'
              {...a11yProps(2)}
            />
            <Tab
              icon={<GroupAddIcon />}
              label='Affiliate Settings'
              {...a11yProps(3)}
            />
            <Tab
              icon={<PermMediaIcon />}
              label='Payment Method'
              {...a11yProps(4)}
            />
          </Tabs>
        </AppBar>
      </SoftBox>
      <TabPanel value={value} index={0}>
        <VersionTab />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FeesTab />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ApprovalTab />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ReferralTab />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <PaymentMethodTab />
      </TabPanel>
    </MainLayout>
  );
}

export default VersionsDetails;
