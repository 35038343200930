import _ from 'lodash';
import * as Yup from 'yup';

export const defaultValues = {
  name: null,
  base_url: null,
  payment_methods: null,
  default_language: null,
  limits: [],
  languages: [],
  enabled: false,
  login_method: null,
  kyc: {
    enabled: false,
    amount: 0,
  },
};

export const schema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .max(100, 'First name is too long, should be less than 100 characters'),
  base_url: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Invalid url!'
    )
    .required('Base URL is required'),
  default_language: Yup.string().required('default language is required'),
  limits: Yup.array(
    Yup.object().shape({
      currency: Yup.string().required('currency is required'),
      min: Yup.number().min(0).required('min is required'),
      max: Yup.number().min(0).required('max is required'),
    })
  ).required('limits is required'),
  languages: Yup.array(
    Yup.object().shape({
      country: Yup.string().required('country is required'),
      language: Yup.string().required('language is required'),
    })
  ),
  currencies_whitelist: Yup.array(
    Yup.object().shape({
      country: Yup.string().required('country is required'),
      currencies: Yup.array(Yup.string()).required('currencies is required'),
    })
  ),
  enabled: Yup.boolean().required('enabled is required'),
  countries: Yup.array(Yup.string()).required('countries is required'),
  login_method: Yup.string().required('Authentication method is required'),
  kyc: Yup.object().shape({
    enabled: Yup.bool(),
    amount: Yup.number(),
  }),
});

export const sanitizeVersionRequest = ({
  id,
  name,
  base_url,
  countries,
  default_language,
  limits,
  languages,
  currencies_whitelist,
  enabled,
  login_method,
  payment_methods,
  kyc,
}) => {
  const payload = _.pickBy(
    {
      id,
      base_url,
      countries,
      name,
      payment_methods,
      default_language,
      limits,
      languages,
      currencies_whitelist,
      login_method,
      kyc,
    },
    _.identity
  );
  return { ...payload, enabled };
};

export const sanitizePaymentMethodRequest = ({
  payment_methods,
  payment_methods_logos,
  id,
  name,
  base_url,
  countries,
  default_language,
  limits,
  languages,
  currencies_whitelist,
  enabled,
  login_method,
  kyc,
}) => {
  return {
    id,
    payment_methods,
    payment_methods_logos,
    name,
    base_url,
    countries,
    default_language,
    limits,
    languages,
    currencies_whitelist,
    enabled,
    login_method,
    kyc,
  };
};
