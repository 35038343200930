import { useEffect, useRef } from 'react';

import SoftBox from 'components/SoftBox';
import SoftDropzoneRoot from 'components/SoftDropzone/SoftDropzoneRoot';
import Dropzone from 'dropzone';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { getToken } from '../../../utils/cache';

import 'dropzone/dist/dropzone.css';

const PaymentMethodLogoDropzone = () => {
  const { id } = useParams();
  const dropzoneRef = useRef();

  useEffect(() => {
    Dropzone.autoDiscover = false;

    function createDropzone() {
      return new Dropzone(dropzoneRef.current, {
        addRemoveLinks: true,
        acceptedFiles: '.png,.jpg,.jpeg',
        maxFilesize: 10, //MB
        headers: { Authorization: 'Bearer ' + getToken() },
      });
    }

    function removeDropzone() {
      if (Dropzone.instances.length > 0)
        Dropzone.instances.forEach((dz) => dz.destroy());
    }

    createDropzone();

    return () => removeDropzone();
  }, []);
  return (
    <SoftDropzoneRoot
      ref={dropzoneRef}
      action={`${process.env.REACT_APP_BASE_URL}/settings/versions/${id}/payment-method/logos`}
      className='form-control dropzone'
    >
      <SoftBox className='fallback'>
        <SoftBox component='input' name='file' type='file' multiple />
      </SoftBox>
    </SoftDropzoneRoot>
  );
};

export default PaymentMethodLogoDropzone;
