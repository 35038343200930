import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import DeleteIcon from '@mui/icons-material/Delete';
import { Card, Stack, DialogActions } from '@mui/material';
import { Grid, Box, Divider, IconButton, Icon } from '@mui/material';
import { CountrySelect, MultipleSelectField } from 'components/FormField';
import FormProvider from 'components/FormProviders';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useFieldArray } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useGetVersion, useUpdateVersion } from '../hooks';
import { sanitizePaymentMethodRequest } from '../schemas/version';
import PaymentMethodLogoDropzone from './PaymentMethodLogoDropzone';

import 'dropzone/dist/dropzone.css';

const paymentMethodsOptions = [
  {
    label: 'Card',
    value: 'Card',
  },
  {
    label: 'Mobile',
    value: 'Mobile',
  },
  {
    label: 'Bank Transfer',
    value: 'Bank Transfer',
  },
];

const LogoContainer = ({ key, image, onDelete }) => {
  return (
    <Grid item xs={2}>
      <SoftBox>
        <SoftBox
          component='img'
          id={key}
          src={image}
          alt={key}
          borderRadius='lg'
          shadow='md'
          width='100%'
          sx={{
            height: '100%',
            objectFit: 'cover',
          }}
        />
        <IconButton
          size='medium'
          color='error'
          sx={{ width: '100%' }}
          onClick={() => {
            onDelete(image);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </SoftBox>
    </Grid>
  );
};
const MediaTab = () => {
  const { id } = useParams();
  const [logos, setLogos] = useState([]);

  const { data: version, isLoading: isFetchingVersion } = useGetVersion({ id });
  const { mutate: updateVersion, isLoading } = useUpdateVersion({ id });

  const methods = useForm();
  const { handleSubmit, reset, control, register } = methods;
  const {
    fields: countryPaymentMethod,
    append: appendCountryPaymentMethod,
    remove: removeCountryPaymentMethod,
  } = useFieldArray({ control, name: 'payment_methods.countries' });

  const onSubmit = (values) => {
    const payload = {
      ...sanitizePaymentMethodRequest(values),
      payment_methods_logos: logos,
    };
    updateVersion(payload);

    // update logos
  };

  const onDeleteLogo = (image) => {
    let _logos = logos;
    _logos = _logos.filter((e) => e !== image);
    setLogos(_logos);
  };

  useEffect(() => {
    setLogos(version?.payment_methods_logos);
    reset(version);
  }, [version]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={1} sm={1} />
      <Grid item xs={10} sm={10}>
        <Card sx={{ overflow: 'visible' }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack lineHeight={0} m={3}>
              <SoftTypography variant='h5' fontWeight='bold'>
                Payment methods
              </SoftTypography>
              <SoftTypography
                variant='button'
                fontWeight='regular'
                color='text'
              >
                manage/update payment methods logos
              </SoftTypography>
            </Stack>

            <Stack m={3}>
              <Divider
                textAlign='left'
                sx={{
                  opacity: 1,
                  backgroundImage: 'none !important',
                  height: 'auto',
                }}
              >
                <SoftTypography variant='caption'>
                  Default Settings
                </SoftTypography>
              </Divider>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MultipleSelectField
                    label='Payment methods'
                    name='payment_methods.default'
                    options={paymentMethodsOptions}
                  />
                </Grid>
              </Grid>
              <SoftTypography variant='body2' sx={{ marginTop: 1 }}>
                Logos
              </SoftTypography>
              <Grid container spacing={3}>
                {logos.map((logo) => (
                  <LogoContainer
                    key={logo}
                    image={logo}
                    onDelete={onDeleteLogo}
                  />
                ))}
              </Grid>
              {/* <SoftDropzone options={{ addRemoveLinks: true }} /> */}
              <PaymentMethodLogoDropzone />

              <Divider
                textAlign='left'
                sx={{
                  opacity: 1,
                  backgroundImage: 'none !important',
                  height: 'auto',
                }}
              >
                <SoftTypography variant='caption'>
                  Countries Settings
                </SoftTypography>
              </Divider>

              {_.map(countryPaymentMethod, (field, index) => (
                <Grid container key={field.id} mb={-1}>
                  <Grid item xs={11}>
                    <Box>
                      <Grid container>
                        <Grid item xs={12} lg={5.7}>
                          <CountrySelect
                            name={`payment_methods.countries[${index}].country`}
                            label={'Country'}
                          />
                        </Grid>
                        <Grid item xs={0} lg={0.3}></Grid>
                        <Grid item xs={12} lg={5.5}>
                          <MultipleSelectField
                            label='Payment methods'
                            name={`payment_methods.countries[${index}].payment_methods`}
                            options={paymentMethodsOptions}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider />
                  </Grid>

                  <Grid item xs={1}>
                    <IconButton
                      onClick={() => removeCountryPaymentMethod(index)}
                      sx={{ mt: 2 }}
                    >
                      <Icon>delete_forever</Icon>
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
              <SoftButton
                sx={{ mt: 2, width: '200px' }}
                onClick={() =>
                  appendCountryPaymentMethod({
                    country: '',
                    payment_methods: '',
                  })
                }
              >
                Add country methods
              </SoftButton>
            </Stack>

            <Stack ml={1}>
              <DialogActions style={{ justifyContent: 'flex-start' }}>
                <SoftButton
                  variant='gradient'
                  color='info'
                  type='submit'
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Update
                </SoftButton>
              </DialogActions>
            </Stack>
          </FormProvider>
        </Card>
      </Grid>
    </Grid>
  );
};

export default MediaTab;
